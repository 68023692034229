import React, { useRef, useEffect, useCallback } from 'react'
import cx from 'classnames'

import s from './Notification.scss'


export type NotificationProps = {
  className?: string
  id?: string | number
  closeNotification?: () => void
  onClick?: () => void
}

const Notification: React.FC<NotificationProps> = (props) => {
  const { children, className, closeNotification, onClick } = props

  const nodeRef = useRef<HTMLDivElement>()

  const handleClose = useCallback(() => {
    nodeRef.current.style.marginTop = `${-1 * nodeRef.current.clientHeight}px`
    nodeRef.current.classList.add(s.closed)
    setTimeout(closeNotification, 300)
  }, [ closeNotification ])

  // Close notification timeout
  useEffect(() => {
    const timer = setTimeout(handleClose, 3000)

    return () => {
      clearTimeout(timer)
    }
  }, [ handleClose ])

  const handleClick = useCallback(() => {
    if (typeof onClick === 'function') {
      onClick()
    }
    else {
      handleClose()
    }
  }, [ handleClose, onClick ])

  return (
    <div
      ref={nodeRef}
      className={cx(s.notification, className, 'relative w-full px-24px py-16px mt-16px radius-16 bg-white shadow-titanic-1')}
      role="alert"
      data-testid="notification"
      onClick={handleClick}
    >
      {children}
    </div>
  )
}


export default Notification
